<template>
  <div width="100vw">
    <div>
      <ejs-splitter id="default-splitter" @resizeStop="onResize">
        <e-panes>
          <e-pane content="#menu" size="400px">Koekoek</e-pane>
          <e-pane content="#drawing" size="400px">Koekoek</e-pane>
          <e-pane content="#nested"></e-pane>
        </e-panes>
      </ejs-splitter>
    </div>

    <!-- pane contents -->
    <div id="menu" class="content-pane"><ProcessListTree /></div>
    <div id="drawing" class="mx-5">
      <DrawIO
        v-if="
          $store.state.activeProcessNode.TestScenarioHeaderId &&
          !$store.state.activeProcessNode.TestScenarioLineId
        "
        :key="drawIoKey"
        :testScenarioHeaderId="
          parseInt($store.state.activeProcessNode.TestScenarioHeaderId)
        "
        height="90vh"
      />
      <DrawIO
        v-if="$store.state.activeProcessNode.VariantId"
        :key="drawIoKey"
        :variantId="parseInt($store.state.activeProcessNode.VariantId)"
        height="90vh"
      />
      <DrawIO
        v-if="
          !$store.state.activeProcessNode.VariantId &&
          !$store.state.activeProcessNode.ProcessId
        "
        :key="drawIoKey"
        :processId="parseInt($store.state.activeProcessNode.ProcessId)"
        height="90vh"
      />
    </div>
    <div class="sticky" id="nested">
      <ejs-splitter orientation="Vertical" @resizeStop="onResize">
        <e-panes>
          <e-pane content="#breadcrumb" size="40px" :resizable="false"></e-pane>
          <e-pane content="#content"></e-pane>
        </e-panes>
      </ejs-splitter>
    </div>

    <div id="breadcrumb">{{ $store.state.activeProcessNode }}</div>
    <div id="content" class="content-pane">
      <TestLineBrowser
        v-if="$store.state.activeProcessNode.TestScenarioLineId"
      />
      <VariantBrowser
        v-if="
          !$store.state.activeProcessNode.VariantId &&
          !$store.state.activeProcessNode.TestScenarioLineId
        "
      />
      <ProcessStepBrowser
        v-if="
          $store.state.activeProcessNode.VariantId &&
          !$store.state.activeProcessNode.TestScenarioLineId
        "
      />
    </div>
  </div>
</template>

<script>
import ProcessListTree from '@/components/Browser/ProcessListTree';
import VariantBrowser from '@/components/Browser/VariantBrowser';
import ProcessStepBrowser from '@/components/Browser/ProcessStepBrowser';
import TestLineBrowser from '@/components/Browser/TestLineBrowser.vue';
import DrawIO from '@/components/Drawing/drawio.vue';
import {
  SplitterComponent,
  PanesDirective,
  PaneDirective,
} from '@syncfusion/ej2-vue-layouts';

export default {
  components: {
    ProcessListTree,
    VariantBrowser,
    ProcessStepBrowser,
    TestLineBrowser,
    DrawIO,
    'ejs-splitter': SplitterComponent,
    'e-pane': PaneDirective,
    'e-panes': PanesDirective,
  },
  data() {
    return {
      drawIoKey: 0,
    };
  },
  methods: {
    onResize() {
      this.drawIoKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
#default-splitter {
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent outer overflow */
}

.content-pane {
  height: calc(100vh - 90px); /* Adjust for any header or footer height */
  overflow-y: auto; /* Enable vertical scrolling only on content */
  /* Ensure proper box model */
  box-sizing: border-box;
}

#menu,
#schedule {
  padding: 10px; /* Optional: Add padding */
}

.content {
  height: 1000px; /* Example long content height */
}

/* Optional: Hide scrollbar for better aesthetics */
#menu::-webkit-scrollbar,
#schedule::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

#menu::-webkit-scrollbar-thumb,
#schedule::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5); /* Color of the scrollbar */
  border-radius: 4px; /* Rounded corners */
}

/* For Firefox */
#menu,
#schedule {
  scrollbar-width: thin; /* Use thin scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* Thumb color and background */
}
</style>
